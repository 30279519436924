import { template as template_c1f517106bb44b75bd1f672bc6fc020f } from "@ember/template-compiler";
const FKLabel = template_c1f517106bb44b75bd1f672bc6fc020f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
