import { template as template_5abce749e5f24660be43fd99b1bbd37d } from "@ember/template-compiler";
const SidebarSectionMessage = template_5abce749e5f24660be43fd99b1bbd37d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
