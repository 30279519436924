import { template as template_bfe26c40295d44d48d99a139395bfe48 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bfe26c40295d44d48d99a139395bfe48(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
